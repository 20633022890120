.slide__container {
  position: relative;
  background-image: url(../../../assets/img/research.png), linear-gradient(to top, #ffffff 0%, #ffffff 100%);
  background-repeat: no-repeat, no-repeat;
  background-attachment: fixed, fixed;
  background-position: bottom right, top center;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  &__content {
    margin-right: 100%;
    transform: translatex(calc(50% + 20px)); // outline-offset: 4px;
    width: calc(50% + 10px);
    &__slogan {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 20px 7px 20px 30px;
      background: hsla(0, 0%, 100%, 0.92);
      max-width: 875px;
      outline: 3px solid #fff;
      box-sizing: border-box;
      transition: background-color 0.25s ease-out;
      &:hover {
        background: hsla(0, 0%, 100%, 1.0);
      }
      &__header {
        font-size: 54px;
        color: #343744;
        font-weight: 600;
        letter-spacing: -1.75px;
        width: 100%;
        line-height: 1.2;
      }
      &__text {
        min-width: 100%;
        font-size: 20px;
        color: #343744;
        font-weight: normal;
        letter-spacing: -0.5px;
      }
    }
    &__button {
      display: block;
      width: min-content;
      border: solid 3px #fff;
      margin-top: 20px;
      background: none;
      font-family: Arial;
      font-size: 28px;
      font-weight: 600;
      text-decoration: none;
      font-style: normal;
      font-stretch: 100%;
      line-height: normal;
      text-align: center;
      word-spacing: 0;
      white-space: nowrap;
      color: #343744;
      background: hsla(0, 0%, 100%, 0.92);
      padding: 12px 47px 12px 50px;
      cursor: pointer;
      transition: background-color 0.05s ease-out;
      &:hover {
        background: hsla(0, 0%, 100%, 1.0);
      }
      &__content {
        position: relative;
        align-items: center;
        justify-content: center;
        display: flex;
        img {
          width: 27px;
          display: inline-flex;
          transform: rotate(-90deg) scaleX(0.87) translateX(0px);
          font-size: 0px;
        }
      }
    }
  }
}

@media screen and (max-width: 2000px) {}

@media screen and (max-width: 1720px) {
  .slide__container__content {
    &__slogan {
      &__header {
        font-size: 54px;
      }
      &__text {
        font-size: 19px;
      }
    }
  }
}

@media screen and (max-width: 1610px) {
  .slide__container__content {
    &__slogan {
      &__header {
        font-size: 51px;
      }
      &__text {
        font-size: 18px;
      }
    }
  }
}

@media screen and (max-width: 1510px) {
  .slide__container__content {
    &__slogan {
      &__header {
        font-size: 46px;
      }
      &__text {
        font-size: 18px;
      }
    }
    &__button {
      font-size: 25px;
      padding: 11px 35px 10px 38px;
      &__content {
        img {
          width: 25px;
          transform: rotate(-90deg) scaleX(0.87) translateX(0px);
        }
      }
    }
  }
}

@media screen and (max-width: 1350px) {
  .slide__container__content {
    &__slogan {
      padding: 15px 5px 15px 20px;
      &__header {
        font-size: 42px;
      }
      &__text {
        font-size: 16px;
      }
    }
  }
}

@media screen and (max-width: 1210px) {
  .slide__container__content {
    &__slogan {
      &__header {
        font-size: 38px;
      }
      &__text {
        font-size: 15px;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .slide__container__content {
    &__slogan {
      padding: 12px 5px 12px 15px;
      &__header {
        font-size: 33px;
      }
      &__text {
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 900px) {}