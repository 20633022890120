header {
  position: fixed;
  z-index: 999;
  width: 100%; // opacity: 0.8;
  justify-content: center;
  display: flex;
  .header {
    position: relative;
    justify-content: center;
    display: flex;
    width: 100%; // padding: 15px 20px;
    max-width: 2200px;
    &::before {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      transition: background-color 0.1s ease-out;
      background: rgba(255, 255, 255, 0.92);
    }
    &:hover::before {
      background: rgba(255, 255, 255, 1.0);
    }
    &__brand {
      position: absolute;
      display: flex;
      height: 100%;
      align-items: center;
      padding: 0px 10px;
      // background: #fff;
      left: 25px; // margin-top: -6px;
      // border-top: 51px solid #fff; // border-left: 50px solid transparent;
      // border-right: 50px solid transparent;
      &__content {
        color: #1c2130;
        font-weight: 300;
        font-size: 23px;
        user-select: none;
        text-decoration: none;
        cursor: pointer;
      }
    }
    &__bar {
      position: relative;
      min-width: 600px; // box-sizing: border-box;
      // background-image: linear-gradient(to right, #272930 0%, #343744 50%, #272930 100%);
      // background-image: linear-gradient(to right, #fffc 0%, #fffc 100%);
      z-index: 1; // width: 100%;
      // background: rgba(255, 255, 255, 0.92);
      // &::before {
      //   position: absolute;
      //   content: "";
      //   top: 0;
      //   right: 0;
      //   bottom: 0;
      //   left: 0;
      //   z-index: -1;
      //   transition: opacity 0.1s ease-out;
      //   border-top: 51px solid #ffffffff;
      //   border-left: 50px solid transparent;
      //   border-right: 50px solid transparent;
      // }
      // &:hover::before {
      //   opacity: 1;
      //   border-top: 51px solid #ffffffff;
      // }
      &__content {
        position: relative;
        max-width: 2400px;
        width: 100%;
        display: flex;
        justify-content: center;
        height: 51px;
        align-items: center;
        &__router-links {
          position: relative;
          padding: 0;
          list-style: none;
          display: flex;
          margin: 0 auto;
          li {
            padding: 0px 10px;
            a {
              text-decoration: none; // color: #fff;
              color: #1c2130; // opacity: 1;
              cursor: pointer;
              user-select: none; // &:hover {
              // color: #f1ff94;
              // opacity: 1;
              // }
            }
            &.active a.about:hover,
            a.about:hover {
              color: #c1d82f;
            }
            &.active a.services:hover,
            a.services:hover {
              color: #ffe74a;
            }
            &.active a.technologies:hover,
            a.technologies:hover {
              color: #ff7b6f;
            }
            &.active a.workflow:hover,
            a.workflow:hover {
              color: #fdbd5c;
            }
            &.active a.contact:hover,
            a.contact:hover {
              color: #61d1fd;
            }
            &.active {
              a.about {
                color: #a8bb2b;
              }
              a.services {
                color: #edd121;
              }
              a.technologies {
                color: #ef5e51;
              }
              a.workflow {
                color: #e89e30;
              }
              a.contact {
                color: #33b2e4;
              }
            }
          }
        }
      }
    }
  }
}