@keyframes off-screen {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes on-screen {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.slide {
  position: relative;
  width: 100%;
  height: 100%;
  pointer-events: all;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  transform: translateX(100%); // transition: transform 1.2s ease-in-out;
  &.current {
    transform: translateX(0%);
  }
  &.off-screen {
    // transform: translateX(0%);
    animation-name: off-screen;
    animation-duration: 0.8s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }
  &.on-screen {
    // transform: translateX(100%);
    animation-name: on-screen;
    animation-duration: 0.8s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
  }
}