@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i);
.Footer_footer__27yVN {
  position: relative;
  z-index: 10;
  display: flex;
  padding: 15px 20px;
  background-color: #3f404b;
  background-image: -webkit-linear-gradient(left, #272930 0%, #343744 50%, #272930 100%);
  background-image: linear-gradient(to right, #272930 0%, #343744 50%, #272930 100%);
  opacity: 1;
  justify-content: center; }
  .Footer_footer__copyright__YDm3u {
    color: #ffffff73; }
    .Footer_footer__copyright__YDm3u:hover {
      color: #ffffffcc; }

header {
  position: fixed;
  z-index: 999;
  width: 100%;
  justify-content: center;
  display: flex; }
  header .Header_header__10HPR {
    position: relative;
    justify-content: center;
    display: flex;
    width: 100%;
    max-width: 2200px; }
    header .Header_header__10HPR::before {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      -webkit-transition: background-color 0.1s ease-out;
      transition: background-color 0.1s ease-out;
      background: rgba(255, 255, 255, 0.92); }
    header .Header_header__10HPR:hover::before {
      background: white; }
    header .Header_header__brand__P2H-y {
      position: absolute;
      display: flex;
      height: 100%;
      align-items: center;
      padding: 0px 10px;
      left: 25px; }
      header .Header_header__brand__content__3muDq {
        color: #1c2130;
        font-weight: 300;
        font-size: 23px;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
        text-decoration: none;
        cursor: pointer; }
    header .Header_header__bar__Vb0vz {
      position: relative;
      min-width: 600px;
      z-index: 1; }
      header .Header_header__bar__content__qeZW_ {
        position: relative;
        max-width: 2400px;
        width: 100%;
        display: flex;
        justify-content: center;
        height: 51px;
        align-items: center; }
        header .Header_header__bar__content__router-links__1ziMs {
          position: relative;
          padding: 0;
          list-style: none;
          display: flex;
          margin: 0 auto; }
          header .Header_header__bar__content__router-links__1ziMs li {
            padding: 0px 10px; }
            header .Header_header__bar__content__router-links__1ziMs li a {
              text-decoration: none;
              color: #1c2130;
              cursor: pointer;
              -webkit-user-select: none;
                 -moz-user-select: none;
                  -ms-user-select: none;
                      user-select: none; }
            header .Header_header__bar__content__router-links__1ziMs li.Header_active__2ga1z a.Header_about__UeWLI:hover,
            header .Header_header__bar__content__router-links__1ziMs li a.Header_about__UeWLI:hover {
              color: #c1d82f; }
            header .Header_header__bar__content__router-links__1ziMs li.Header_active__2ga1z a.Header_services__2a1ay:hover,
            header .Header_header__bar__content__router-links__1ziMs li a.Header_services__2a1ay:hover {
              color: #ffe74a; }
            header .Header_header__bar__content__router-links__1ziMs li.Header_active__2ga1z a.Header_technologies__3oXox:hover,
            header .Header_header__bar__content__router-links__1ziMs li a.Header_technologies__3oXox:hover {
              color: #ff7b6f; }
            header .Header_header__bar__content__router-links__1ziMs li.Header_active__2ga1z a.Header_workflow__3Rlb1:hover,
            header .Header_header__bar__content__router-links__1ziMs li a.Header_workflow__3Rlb1:hover {
              color: #fdbd5c; }
            header .Header_header__bar__content__router-links__1ziMs li.Header_active__2ga1z a.Header_contact__1VMck:hover,
            header .Header_header__bar__content__router-links__1ziMs li a.Header_contact__1VMck:hover {
              color: #61d1fd; }
            header .Header_header__bar__content__router-links__1ziMs li.Header_active__2ga1z a.Header_about__UeWLI {
              color: #a8bb2b; }
            header .Header_header__bar__content__router-links__1ziMs li.Header_active__2ga1z a.Header_services__2a1ay {
              color: #edd121; }
            header .Header_header__bar__content__router-links__1ziMs li.Header_active__2ga1z a.Header_technologies__3oXox {
              color: #ef5e51; }
            header .Header_header__bar__content__router-links__1ziMs li.Header_active__2ga1z a.Header_workflow__3Rlb1 {
              color: #e89e30; }
            header .Header_header__bar__content__router-links__1ziMs li.Header_active__2ga1z a.Header_contact__1VMck {
              color: #33b2e4; }

.Landing_top-bar__3LQMq {
  height: 51px; }

.Landing_section__body__nGn-z {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 2400px;
  margin: auto; }

.Landing_arrow-container__36dJH {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  bottom: 25px;
  width: 64px;
  height: 64px;
  background: none;
  background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.3) 100%);
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.3) 100%);
  border-radius: 100%;
  border: solid 1.5px #ffffffff;
  opacity: 0.6;
  -webkit-transition: opacity 0.1s ease-out;
  transition: opacity 0.1s ease-out; }
  .Landing_arrow-container__36dJH:hover {
    opacity: 1; }
  .Landing_arrow-container__arrow-white__3mO93 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%; }
    .Landing_arrow-container__arrow-white__3mO93.Landing_up__2bKMX {
      -webkit-transform: rotate(180deg) translateY(2px);
              transform: rotate(180deg) translateY(2px); }
    .Landing_arrow-container__arrow-white__3mO93 img {
      pointer-events: none;
      width: 50%;
      height: 50%;
      -webkit-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none; }

.Landing_section-two__32lJZ {
  background-image: url(/static/media/sectionL.bfa4b287.png), url(/static/media/about.926cce0e.png), -webkit-linear-gradient(left, #6e665e 0%, #6c6660 30%, #f7f6f2 calc(50% + 1200px), #f7f6f2 100%);
  background-image: url(/static/media/sectionL.bfa4b287.png), url(/static/media/about.926cce0e.png), linear-gradient(to right, #6e665e 0%, #6c6660 30%, #f7f6f2 calc(50% + 1200px), #f7f6f2 100%);
  background-repeat: no-repeat, no-repeat;
  background-attachment: scroll, scroll;
  background-position: top center, top center; }
  .Landing_section-two__32lJZ .Landing_text-section__content__body-container__header__13LVA {
    color: #bed147; }

.Landing_section-three__3l8OY {
  background-image: url(/static/media/sectionR.13a7eb6f.png), url(/static/media/services.92cd9d61.png), -webkit-linear-gradient(bottom, #19191a 0%, #1b1c1d 100%);
  background-image: url(/static/media/sectionR.13a7eb6f.png), url(/static/media/services.92cd9d61.png), linear-gradient(to top, #19191a 0%, #1b1c1d 100%);
  background-repeat: no-repeat, no-repeat;
  background-attachment: scroll, scroll;
  background-position: top center, top center; }
  .Landing_section-three__3l8OY .Landing_text-section__content__body-container__header__13LVA {
    color: #edd221; }

.Landing_section-four__3X9HS {
  background-image: url(/static/media/sectionL.bfa4b287.png), url(/static/media/technologies.3cdc3667.png), -webkit-linear-gradient(left, #ebf0ff 0%, #ebf0ff 40%, #9ec5f5 100%);
  background-image: url(/static/media/sectionL.bfa4b287.png), url(/static/media/technologies.3cdc3667.png), linear-gradient(to right, #ebf0ff 0%, #ebf0ff 40%, #9ec5f5 100%);
  background-repeat: no-repeat, no-repeat;
  background-attachment: scroll, scroll;
  background-position: top center, top center; }
  .Landing_section-four__3X9HS .Landing_text-section__content__body-container__header__13LVA {
    color: #f05f51; }

.Landing_section-five__-hDfO {
  background-image: url(/static/media/sectionR.13a7eb6f.png), url(/static/media/workflow.56fa3414.png), -webkit-linear-gradient(left, #c0957c 0%, #c5a18c 50%, #d2d7da 50%, #d2d7da 100%);
  background-image: url(/static/media/sectionR.13a7eb6f.png), url(/static/media/workflow.56fa3414.png), linear-gradient(to right, #c0957c 0%, #c5a18c 50%, #d2d7da 50%, #d2d7da 100%);
  background-repeat: no-repeat, no-repeat;
  background-attachment: scroll, scroll;
  background-position: top center, top center; }
  .Landing_section-five__-hDfO .Landing_text-section__content__body-container__header__13LVA {
    color: #e89f30; }

.Landing_section-six__1eb-o {
  background-image: url(/static/media/sectionL.bfa4b287.png), url(/static/media/contact.699e4f41.png), -webkit-linear-gradient(bottom, #ddd9d4 0%, #e8e8e8 100%);
  background-image: url(/static/media/sectionL.bfa4b287.png), url(/static/media/contact.699e4f41.png), linear-gradient(to top, #ddd9d4 0%, #e8e8e8 100%);
  background-repeat: no-repeat, no-repeat;
  background-attachment: scroll, scroll;
  background-position: top center, top center; }
  .Landing_section-six__1eb-o .Landing_text-section__content__body-container__header__13LVA {
    color: #34b3e5; }

.Landing_return-arrow-section__T0Opi {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100px;
  margin-bottom: calc(((100vh - 700px) / 7) * 2); }

.Landing_text-section__5hgDM {
  height: 700px;
  margin-top: calc(((100vh - 700px) / 7) * 5);
  margin-bottom: calc(((100vh - 700px) / 7) * 2);
  border-top: solid 5px #e6e6e6;
  border-bottom: solid 5px #e6e6e6;
  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  -webkit-clip-path: polygon(calc(50% - 1250px) 0%, 100% 0%, 100% 100%, calc(50% - 1250px) 100%);
          clip-path: polygon(calc(50% - 1250px) 0%, 100% 0%, 100% 100%, calc(50% - 1250px) 100%); }
  .Landing_text-section__5hgDM.Landing_right__ayZOa {
    -webkit-clip-path: polygon(calc(50% + 1250px) 0%, 0% 0%, 0% 100%, calc(50% + 1250px) 100%);
            clip-path: polygon(calc(50% + 1250px) 0%, 0% 0%, 0% 100%, calc(50% + 1250px) 100%); }
    .Landing_text-section__5hgDM.Landing_right__ayZOa .Landing_text-section__content__2clWP {
      justify-content: flex-start; }
      .Landing_text-section__5hgDM.Landing_right__ayZOa .Landing_text-section__content__body-container__bdPkP {
        margin-left: 60%;
        margin-right: 20px; }
  .Landing_text-section__content__2clWP {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end; }
    .Landing_text-section__content__body-container__bdPkP {
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      margin-right: 60%;
      max-width: 720px;
      flex: 1 1;
      justify-content: center;
      align-items: center; }
      .Landing_text-section__content__body-container__header__13LVA {
        position: absolute;
        top: 20px;
        left: 0;
        font-size: 100px;
        font-weight: 700;
        padding: 0px;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
        white-space: nowrap;
        color: #e6e6e6; }
      .Landing_text-section__content__body-container__text-container__-2osh {
        z-index: 2;
        padding: 10px;
        min-height: 200px;
        width: 100%;
        background-clip: padding-box; }
        .Landing_text-section__content__body-container__text-container__text__oX462 {
          font-size: 22px;
          font-weight: normal;
          list-style-type: square; }
      .Landing_text-section__content__body-container__logo-container__3sS5c {
        z-index: 2;
        padding: 20px 40px;
        min-height: 200px;
        width: 100%;
        margin: 175px 0px 50px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: flex-start; }
        .Landing_text-section__content__body-container__logo-container__logo__SjBtI {
          display: block;
          margin-bottom: 20px;
          flex: 1 1;
          flex-grow: 0; }
          .Landing_text-section__content__body-container__logo-container__logo__SjBtI img {
            display: block;
            width: 100%;
            min-width: 145px; }

@media screen and (min-height: 1200px) {
  .Landing_text-section__5hgDM {
    margin-top: 250px;
    margin-bottom: 100px; }
  .Landing_return-arrow-section__T0Opi {
    margin-bottom: 100px; } }

@media screen and (max-width: 2000px) {
  .Landing_text-section__5hgDM.Landing_section-six__1eb-o .Landing_text-section__content__body-container__text-container__text__oX462 {
    font-size: 24px; } }

@media screen and (max-width: 1510px) {
  .Landing_text-section__5hgDM {
    background-position-x: calc(50% + 50px), calc(50% - 30px), 50%; }
    .Landing_text-section__content__body-container__header__13LVA {
      font-size: 95px; }
    .Landing_text-section__content__body-container__text-container__text__oX462 {
      font-size: 20px; }
    .Landing_text-section__5hgDM.Landing_right__ayZOa {
      background-position-x: calc(50% - 50px), calc(50% + 30px), 50%; }
      .Landing_text-section__5hgDM.Landing_right__ayZOa .Landing_text-section__content__body-container__bdPkP {
        margin-left: calc(60% - 40px); }
    .Landing_text-section__content__body-container__bdPkP {
      margin-right: calc(60% - 40px); }
      .Landing_text-section__content__body-container__logo-container__logo__SjBtI img {
        min-width: 135px; } }

@media screen and (max-width: 1350px) {
  .Landing_text-section__5hgDM.Landing_section-five__-hDfO .Landing_text-section__content__body-container__text-container__text__oX462, .Landing_text-section__5hgDM.Landing_section-two__32lJZ .Landing_text-section__content__body-container__text-container__text__oX462 {
    font-size: 20px; }
  .Landing_text-section__content__body-container__text-container__text__oX462 {
    font-size: 18px; } }

@media screen and (max-width: 1210px) {
  .Landing_text-section__5hgDM.Landing_section-two__32lJZ .Landing_text-section__content__body-container__text-container__text__oX462 {
    font-size: 18px; }
  .Landing_text-section__content__body-container__header__13LVA {
    font-size: 90px; }
  .Landing_text-section__content__body-container__text-container__text__oX462 {
    font-size: 18px; } }

@media screen and (max-width: 1100px) {
  .Landing_text-section__5hgDM {
    height: 675px;
    background-position-x: calc(50% + 100px), calc(50% - 50px), 50%; }
    .Landing_text-section__5hgDM.Landing_right__ayZOa {
      background-position-x: calc(50% - 100px), calc(50% + 50px), 50%; }
      .Landing_text-section__5hgDM.Landing_right__ayZOa .Landing_text-section__content__body-container__bdPkP {
        margin-left: calc(60% - 50px); }
    .Landing_text-section__content__body-container__bdPkP {
      margin-right: calc(60% - 50px); }
      .Landing_text-section__content__body-container__header__13LVA {
        font-size: 80px; }
      .Landing_text-section__content__body-container__logo-container__logo__SjBtI img {
        min-width: 120px; } }

@media screen and (max-width: 900px) {
  .Landing_text-section__5hgDM {
    background-position-x: calc(50% + 120px), calc(50% - 75px), 50%; }
    .Landing_text-section__5hgDM.Landing_section-six__1eb-o .Landing_text-section__content__body-container__text-container__text__oX462 {
      font-size: 20px; }
    .Landing_text-section__content__body-container__text-container__text__oX462 {
      font-size: 16px; }
    .Landing_text-section__5hgDM.Landing_right__ayZOa {
      background-position-x: calc(50% - 120px), calc(50% + 75px), 50%; }
      .Landing_text-section__5hgDM.Landing_right__ayZOa .Landing_text-section__content__body-container__bdPkP {
        margin-left: calc(60% - 70px); }
    .Landing_text-section__content__body-container__bdPkP {
      margin-right: calc(60% - 70px); }
      .Landing_text-section__content__body-container__logo-container__logo__SjBtI img {
        min-width: 110px; } }

.Slider_section__body__hk5JZ {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 2400px;
  margin: auto; }

.Slider_section-one__1KsMs {
  overflow: hidden;
  height: calc(100vh - 150px);
  margin-bottom: 5px;
  border-bottom: solid 5px #e6e6e6; }
  .Slider_section-one__slides__1FE3t {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    overflow: hidden; }
    .Slider_section-one__slides__slide-wrapper__3L_eR {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      pointer-events: none; }
    .Slider_section-one__slides__navs__2e5Yz {
      position: absolute;
      bottom: 8px;
      left: 50%;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      display: flex; }
      .Slider_section-one__slides__navs__button-wrapper__3mNsB {
        border: 1.5px solid rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        margin: 2px; }
        .Slider_section-one__slides__navs__button-wrapper__3mNsB button {
          display: flex;
          background: none;
          cursor: pointer;
          border: 1.5px solid white;
          width: 18px;
          height: 18px;
          background: rgba(255, 255, 255, 0.2);
          border-radius: 50%; }
          .Slider_section-one__slides__navs__button-wrapper__3mNsB button:hover {
            background: rgba(255, 255, 255, 0.4); }
          .Slider_section-one__slides__navs__button-wrapper__3mNsB button:focus {
            outline: 0; }
          .Slider_section-one__slides__navs__button-wrapper__3mNsB button.Slider_current__2pnkg {
            background: rgba(255, 255, 255, 0.8); }
            .Slider_section-one__slides__navs__button-wrapper__3mNsB button.Slider_current__2pnkg:hover {
              background: rgba(255, 255, 255, 0.92); }

@-webkit-keyframes Slide_off-screen__3bUVX {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); } }

@keyframes Slide_off-screen__3bUVX {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); } }

@-webkit-keyframes Slide_on-screen__9zW5Z {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

@keyframes Slide_on-screen__9zW5Z {
  0% {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); } }

.Slide_slide__1UgGr {
  position: relative;
  width: 100%;
  height: 100%;
  pointer-events: all;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }
  .Slide_slide__1UgGr.Slide_current__AxPWS {
    -webkit-transform: translateX(0%);
            transform: translateX(0%); }
  .Slide_slide__1UgGr.Slide_off-screen__3bUVX {
    -webkit-animation-name: Slide_off-screen__3bUVX;
            animation-name: Slide_off-screen__3bUVX;
    -webkit-animation-duration: 0.8s;
            animation-duration: 0.8s;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }
  .Slide_slide__1UgGr.Slide_on-screen__9zW5Z {
    -webkit-animation-name: Slide_on-screen__9zW5Z;
            animation-name: Slide_on-screen__9zW5Z;
    -webkit-animation-duration: 0.8s;
            animation-duration: 0.8s;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards; }

.MainSlide_slide__container__2Y3Z4 {
  position: relative;
  background-image: url(/static/media/section1.23721505.png), -webkit-linear-gradient(bottom, #ffffff 0%, #ffffff 100%);
  background-image: url(/static/media/section1.23721505.png), linear-gradient(to top, #ffffff 0%, #ffffff 100%);
  background-repeat: no-repeat, no-repeat;
  background-attachment: fixed, fixed;
  background-position: bottom right, top center;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0; }
  .MainSlide_slide__container__content__2J1S- {
    margin-right: 100%;
    -webkit-transform: translatex(calc(50% + 20px));
            transform: translatex(calc(50% + 20px));
    width: calc(50% + 10px); }
    .MainSlide_slide__container__content__slogan__3xhy7 {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 20px 7px 20px 30px;
      background: rgba(255, 255, 255, 0.92);
      max-width: 875px;
      outline: 3px solid #fff;
      box-sizing: border-box;
      -webkit-transition: background-color 0.25s ease-out;
      transition: background-color 0.25s ease-out; }
      .MainSlide_slide__container__content__slogan__3xhy7:hover {
        background: white; }
      .MainSlide_slide__container__content__slogan__header__2tCgY {
        font-size: 54px;
        color: #343744;
        font-weight: 600;
        letter-spacing: -1.75px;
        width: 100%;
        line-height: 1.2; }
      .MainSlide_slide__container__content__slogan__text__1JrsG {
        min-width: 100%;
        font-size: 20px;
        color: #343744;
        font-weight: normal;
        letter-spacing: -0.5px; }
    .MainSlide_slide__container__content__button__2ZMg8 {
      display: block;
      width: -webkit-min-content;
      width: -moz-min-content;
      width: min-content;
      border: solid 3px #fff;
      margin-top: 20px;
      background: none;
      font-family: Arial;
      font-size: 26px;
      font-weight: 600;
      text-decoration: none;
      font-style: normal;
      font-stretch: 100%;
      line-height: normal;
      text-align: center;
      word-spacing: 0;
      white-space: nowrap;
      color: #343744;
      background: rgba(255, 255, 255, 0.92);
      padding: 12px 47px 12px 50px;
      cursor: pointer;
      -webkit-transition: background-color 0.05s ease-out;
      transition: background-color 0.05s ease-out; }
      .MainSlide_slide__container__content__button__2ZMg8:hover {
        background: white; }
      .MainSlide_slide__container__content__button__content__3b5xl {
        position: relative;
        align-items: center;
        justify-content: center;
        display: flex; }
        .MainSlide_slide__container__content__button__content__3b5xl img {
          width: 27px;
          display: inline-flex;
          -webkit-transform: rotate(-90deg) scaleX(0.87) translateX(1px);
                  transform: rotate(-90deg) scaleX(0.87) translateX(1px);
          font-size: 0px; }

@media screen and (max-width: 1720px) {
  .MainSlide_slide__container__content__slogan__header__2tCgY {
    font-size: 54px; }
  .MainSlide_slide__container__content__slogan__text__1JrsG {
    font-size: 19px; } }

@media screen and (max-width: 1610px) {
  .MainSlide_slide__container__content__slogan__header__2tCgY {
    font-size: 51px; }
  .MainSlide_slide__container__content__slogan__text__1JrsG {
    font-size: 18px; } }

@media screen and (max-width: 1510px) {
  .MainSlide_slide__container__content__slogan__header__2tCgY {
    font-size: 46px; }
  .MainSlide_slide__container__content__slogan__text__1JrsG {
    font-size: 18px; }
  .MainSlide_slide__container__content__button__2ZMg8 {
    font-size: 25px;
    padding: 11px 35px 10px 38px; }
    .MainSlide_slide__container__content__button__content__3b5xl img {
      width: 25px;
      -webkit-transform: rotate(-90deg) scaleX(0.87) translateX(0px);
              transform: rotate(-90deg) scaleX(0.87) translateX(0px); } }

@media screen and (max-width: 1350px) {
  .MainSlide_slide__container__content__slogan__3xhy7 {
    padding: 15px 5px 15px 20px; }
    .MainSlide_slide__container__content__slogan__header__2tCgY {
      font-size: 42px; }
    .MainSlide_slide__container__content__slogan__text__1JrsG {
      font-size: 16px; } }

@media screen and (max-width: 1210px) {
  .MainSlide_slide__container__content__slogan__header__2tCgY {
    font-size: 38px; }
  .MainSlide_slide__container__content__slogan__text__1JrsG {
    font-size: 15px; } }

@media screen and (max-width: 1100px) {
  .MainSlide_slide__container__content__slogan__3xhy7 {
    padding: 12px 5px 12px 15px; }
    .MainSlide_slide__container__content__slogan__header__2tCgY {
      font-size: 33px; }
    .MainSlide_slide__container__content__slogan__text__1JrsG {
      font-size: 14px; } }

.ProductSlide_slide__container__O-B6A {
  position: relative;
  background-image: url(/static/media/screen.924fcee9.png), -webkit-linear-gradient(bottom, #ffffff 0%, #ffffff 100%);
  background-image: url(/static/media/screen.924fcee9.png), linear-gradient(to top, #ffffff 0%, #ffffff 100%);
  background-repeat: no-repeat, no-repeat;
  background-attachment: fixed, fixed;
  background-position: bottom right, top center;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0; }
  .ProductSlide_slide__container__content__2-_E1 {
    margin-right: 100%;
    -webkit-transform: translatex(calc(50% + 20px));
            transform: translatex(calc(50% + 20px));
    width: calc(50% + 10px); }
    .ProductSlide_slide__container__content__slogan__1Iomj {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 20px 7px 20px 30px;
      background: rgba(255, 255, 255, 0.92);
      max-width: 875px;
      outline: 3px solid #fff;
      box-sizing: border-box;
      -webkit-transition: background-color 0.25s ease-out;
      transition: background-color 0.25s ease-out; }
      .ProductSlide_slide__container__content__slogan__1Iomj:hover {
        background: white; }
      .ProductSlide_slide__container__content__slogan__header__1l3WV {
        font-size: 54px;
        color: #343744;
        font-weight: 600;
        letter-spacing: -1.75px;
        width: 100%;
        line-height: 1.2; }
      .ProductSlide_slide__container__content__slogan__text__1ZQVO {
        min-width: 100%;
        font-size: 20px;
        color: #343744;
        font-weight: normal;
        letter-spacing: -0.5px; }
    .ProductSlide_slide__container__content__button__1U5ar {
      display: block;
      width: -webkit-min-content;
      width: -moz-min-content;
      width: min-content;
      border: solid 3px #fff;
      margin-top: 20px;
      background: none;
      font-family: Arial;
      font-size: 28px;
      font-weight: 600;
      text-decoration: none;
      font-style: normal;
      font-stretch: 100%;
      line-height: normal;
      text-align: center;
      word-spacing: 0;
      white-space: nowrap;
      color: #343744;
      background: rgba(255, 255, 255, 0.92);
      padding: 12px 47px 12px 50px;
      cursor: pointer;
      -webkit-transition: background-color 0.05s ease-out;
      transition: background-color 0.05s ease-out; }
      .ProductSlide_slide__container__content__button__1U5ar:hover {
        background: white; }
      .ProductSlide_slide__container__content__button__content__3rU8l {
        position: relative;
        align-items: center;
        justify-content: center;
        display: flex; }
        .ProductSlide_slide__container__content__button__content__3rU8l img {
          width: 27px;
          display: inline-flex;
          -webkit-transform: rotate(-90deg) scaleX(0.87) translateX(0px);
                  transform: rotate(-90deg) scaleX(0.87) translateX(0px);
          font-size: 0px; }

@media screen and (max-width: 1720px) {
  .ProductSlide_slide__container__content__slogan__header__1l3WV {
    font-size: 54px; }
  .ProductSlide_slide__container__content__slogan__text__1ZQVO {
    font-size: 19px; } }

@media screen and (max-width: 1610px) {
  .ProductSlide_slide__container__content__slogan__header__1l3WV {
    font-size: 51px; }
  .ProductSlide_slide__container__content__slogan__text__1ZQVO {
    font-size: 18px; } }

@media screen and (max-width: 1510px) {
  .ProductSlide_slide__container__content__slogan__header__1l3WV {
    font-size: 46px; }
  .ProductSlide_slide__container__content__slogan__text__1ZQVO {
    font-size: 18px; }
  .ProductSlide_slide__container__content__button__1U5ar {
    font-size: 25px;
    padding: 11px 35px 10px 38px; }
    .ProductSlide_slide__container__content__button__content__3rU8l img {
      width: 25px;
      -webkit-transform: rotate(-90deg) scaleX(0.87) translateX(0px);
              transform: rotate(-90deg) scaleX(0.87) translateX(0px); } }

@media screen and (max-width: 1350px) {
  .ProductSlide_slide__container__content__slogan__1Iomj {
    padding: 15px 5px 15px 20px; }
    .ProductSlide_slide__container__content__slogan__header__1l3WV {
      font-size: 42px; }
    .ProductSlide_slide__container__content__slogan__text__1ZQVO {
      font-size: 16px; } }

@media screen and (max-width: 1210px) {
  .ProductSlide_slide__container__content__slogan__header__1l3WV {
    font-size: 38px; }
  .ProductSlide_slide__container__content__slogan__text__1ZQVO {
    font-size: 15px; } }

@media screen and (max-width: 1100px) {
  .ProductSlide_slide__container__content__slogan__1Iomj {
    padding: 12px 5px 12px 15px; }
    .ProductSlide_slide__container__content__slogan__header__1l3WV {
      font-size: 33px; }
    .ProductSlide_slide__container__content__slogan__text__1ZQVO {
      font-size: 14px; } }

.TechnologiesSlide_slide__container__FcPq_ {
  position: relative;
  background-image: url(/static/media/research.f20af6f1.png), -webkit-linear-gradient(bottom, #ffffff 0%, #ffffff 100%);
  background-image: url(/static/media/research.f20af6f1.png), linear-gradient(to top, #ffffff 0%, #ffffff 100%);
  background-repeat: no-repeat, no-repeat;
  background-attachment: fixed, fixed;
  background-position: bottom right, top center;
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0; }
  .TechnologiesSlide_slide__container__content__34XHO {
    margin-right: 100%;
    -webkit-transform: translatex(calc(50% + 20px));
            transform: translatex(calc(50% + 20px));
    width: calc(50% + 10px); }
    .TechnologiesSlide_slide__container__content__slogan__3dcrP {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 20px 7px 20px 30px;
      background: rgba(255, 255, 255, 0.92);
      max-width: 875px;
      outline: 3px solid #fff;
      box-sizing: border-box;
      -webkit-transition: background-color 0.25s ease-out;
      transition: background-color 0.25s ease-out; }
      .TechnologiesSlide_slide__container__content__slogan__3dcrP:hover {
        background: white; }
      .TechnologiesSlide_slide__container__content__slogan__header__39zq6 {
        font-size: 54px;
        color: #343744;
        font-weight: 600;
        letter-spacing: -1.75px;
        width: 100%;
        line-height: 1.2; }
      .TechnologiesSlide_slide__container__content__slogan__text__xJVWp {
        min-width: 100%;
        font-size: 20px;
        color: #343744;
        font-weight: normal;
        letter-spacing: -0.5px; }
    .TechnologiesSlide_slide__container__content__button__3dBIp {
      display: block;
      width: -webkit-min-content;
      width: -moz-min-content;
      width: min-content;
      border: solid 3px #fff;
      margin-top: 20px;
      background: none;
      font-family: Arial;
      font-size: 28px;
      font-weight: 600;
      text-decoration: none;
      font-style: normal;
      font-stretch: 100%;
      line-height: normal;
      text-align: center;
      word-spacing: 0;
      white-space: nowrap;
      color: #343744;
      background: rgba(255, 255, 255, 0.92);
      padding: 12px 47px 12px 50px;
      cursor: pointer;
      -webkit-transition: background-color 0.05s ease-out;
      transition: background-color 0.05s ease-out; }
      .TechnologiesSlide_slide__container__content__button__3dBIp:hover {
        background: white; }
      .TechnologiesSlide_slide__container__content__button__content__Y0GGG {
        position: relative;
        align-items: center;
        justify-content: center;
        display: flex; }
        .TechnologiesSlide_slide__container__content__button__content__Y0GGG img {
          width: 27px;
          display: inline-flex;
          -webkit-transform: rotate(-90deg) scaleX(0.87) translateX(0px);
                  transform: rotate(-90deg) scaleX(0.87) translateX(0px);
          font-size: 0px; }

@media screen and (max-width: 1720px) {
  .TechnologiesSlide_slide__container__content__slogan__header__39zq6 {
    font-size: 54px; }
  .TechnologiesSlide_slide__container__content__slogan__text__xJVWp {
    font-size: 19px; } }

@media screen and (max-width: 1610px) {
  .TechnologiesSlide_slide__container__content__slogan__header__39zq6 {
    font-size: 51px; }
  .TechnologiesSlide_slide__container__content__slogan__text__xJVWp {
    font-size: 18px; } }

@media screen and (max-width: 1510px) {
  .TechnologiesSlide_slide__container__content__slogan__header__39zq6 {
    font-size: 46px; }
  .TechnologiesSlide_slide__container__content__slogan__text__xJVWp {
    font-size: 18px; }
  .TechnologiesSlide_slide__container__content__button__3dBIp {
    font-size: 25px;
    padding: 11px 35px 10px 38px; }
    .TechnologiesSlide_slide__container__content__button__content__Y0GGG img {
      width: 25px;
      -webkit-transform: rotate(-90deg) scaleX(0.87) translateX(0px);
              transform: rotate(-90deg) scaleX(0.87) translateX(0px); } }

@media screen and (max-width: 1350px) {
  .TechnologiesSlide_slide__container__content__slogan__3dcrP {
    padding: 15px 5px 15px 20px; }
    .TechnologiesSlide_slide__container__content__slogan__header__39zq6 {
      font-size: 42px; }
    .TechnologiesSlide_slide__container__content__slogan__text__xJVWp {
      font-size: 16px; } }

@media screen and (max-width: 1210px) {
  .TechnologiesSlide_slide__container__content__slogan__header__39zq6 {
    font-size: 38px; }
  .TechnologiesSlide_slide__container__content__slogan__text__xJVWp {
    font-size: 15px; } }

@media screen and (max-width: 1100px) {
  .TechnologiesSlide_slide__container__content__slogan__3dcrP {
    padding: 12px 5px 12px 15px; }
    .TechnologiesSlide_slide__container__content__slogan__header__39zq6 {
      font-size: 33px; }
    .TechnologiesSlide_slide__container__content__slogan__text__xJVWp {
      font-size: 14px; } }

.accountData {
  color: #575d6d; }

body {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  font-family: 'Open Sans';
  color: #1c2130;
  font-weight: 600;
  background-color: #ffffff; }

