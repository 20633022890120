@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i,800,800i');
body {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 100vh;
  padding: 0;
  margin: 0;
  font-family: 'Open Sans';
  color: #1c2130;
  font-weight: 600;
  // background-image: linear-gradient(to right, #57595c 0%, #57595c 50%, #57595c 100%);
  background-color: #ffffff;
}