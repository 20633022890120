.section {
  &__body {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 2400px;
    margin: auto;
  }
}

.section-one {
  overflow: hidden;
  height: calc(100vh - 150px);
  margin-bottom: 5px;
  border-bottom: solid 5px #e6e6e6;
  &__slides {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    overflow: hidden;
    &__slide-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      pointer-events: none;
    }
    &__navs {
      position: absolute;
      bottom: 8px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      &__button-wrapper {
        border: 1.5px solid hsla(0, 0%, 0%, 0.1);
        border-radius: 50%;
        margin: 2px;
        button {
          display: flex;
          background: none;
          cursor: pointer;
          border: 1.5px solid white;
          width: 18px;
          height: 18px;
          background: hsla(0, 0%, 100%, 0.2); // border-radius: 2px;
          border-radius: 50%;
          &:hover {
            background: hsla(0, 0%, 100%, 0.4);
          }
          &:focus {
            outline: 0;
          }
          &.current {
            background: hsla(0, 0%, 100%, 0.8);
            &:hover {
              background: hsla(0, 0%, 100%, 0.92);
            } // cursor: default;
          }
        }
      }
    }
  }
}