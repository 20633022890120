.footer {
  position: relative;
  z-index: 10;
  display: flex;
  padding: 15px 20px;
  background-color: #3f404b;
  background-image: linear-gradient(to right, #272930 0%, #343744 50%, #272930 100%);
  opacity: 1; // width: 100%;
  justify-content: center;
  // border-top: solid #000000;
  &__copyright {
    color: #ffffff73;
    &:hover {
      color: #ffffffcc;
    }
  }
}