.top-bar {
  height: 51px; // background: #9799a5;
}

.section {
  &__body {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 2400px;
    margin: auto;
  }
}

.arrow-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  bottom: 25px;
  width: 64px;
  height: 64px;
  background: none;
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.30) 100%);
  border-radius: 100%;
  border: solid 1.5px #ffffffff;
  opacity: 0.6;
  transition: opacity 0.1s ease-out;
  &:hover {
    opacity: 1;
  }
  &__arrow-white {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    &.up {
      transform: rotate(180deg) translateY(2px);
    }
    img {
      pointer-events: none;
      width: 50%;
      height: 50%;
      user-select: none;
    }
  }
}


.section-two {
  background-image: url(../../assets/img/sectionL.png), url(../../assets/img/about.png), linear-gradient(to right, hsl(30, 8%, 40%) 0%, hsl(30, 6%, 40%) 30%, hsl(45, 26%, 96%) calc(50% + 1200px), hsl(45, 26%, 96%) 100%);
  background-repeat: no-repeat, no-repeat;
  background-attachment: scroll, scroll;
  background-position: top center, top center;
  .text-section__content__body-container__header {
    color: #bed147;
  }
}

.section-three {
  background-image: url(../../assets/img/sectionR.png), url(../../assets/img/services.png), linear-gradient(to top, hsl(240, 2%, 10%) 0%, hsl(210, 3%, 11%) 100%);
  background-repeat: no-repeat, no-repeat;
  background-attachment: scroll, scroll;
  background-position: top center, top center;
  .text-section__content__body-container__header {
    color: hsl(52, 85%, 53%);
  }
}

.section-four {
  background-image: url(../../assets/img/sectionL.png), url(../../assets/img/technologies.png), linear-gradient(to right, hsl(225, 100%, 96%) 0%, hsl(225, 100%, 96%) 40%, hsl(213, 81%, 79%) 100%);
  background-repeat: no-repeat, no-repeat;
  background-attachment: scroll, scroll;
  background-position: top center, top center;
  .text-section__content__body-container__header {
    color: hsl(5, 84%, 63%);
  }
}

.section-five {
  background-image: url(../../assets/img/sectionR.png), url(../../assets/img/workflow.png), linear-gradient(to right, hsl(22, 35%, 62%) 0%, hsl(22, 33%, 66%) 50%, hsl(201, 10%, 84%) 50%, hsl(201, 10%, 84%) 100%);
  background-repeat: no-repeat, no-repeat;
  background-attachment: scroll, scroll;
  background-position: top center, top center;
  .text-section__content__body-container__header {
    color: hsl(36, 80%, 55%);
  }
}

.section-six {
  background-image: url(../../assets/img/sectionL.png), url(../../assets/img/contact.png), linear-gradient(to top, #ddd9d4 0%, #e8e8e8 100%);
  background-repeat: no-repeat, no-repeat;
  background-attachment: scroll, scroll;
  background-position: top center, top center;
  .text-section__content__body-container__header {
    color: hsl(197, 77%, 55%);
  }
}

.return-arrow-section {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 100px;
  margin-bottom: calc(((100vh - 700px) / 7) * 2);
}

.text-section {
  height: 700px;
  margin-top: calc(((100vh - 700px) / 7) * 5);
  margin-bottom: calc(((100vh - 700px) / 7) * 2);
  border-top: solid 5px #e6e6e6;
  border-bottom: solid 5px #e6e6e6;
  transform: translateZ(0);
  clip-path: polygon( calc(50% - 1250px) 0%, 100% 0%, 100% 100%, calc(50% - 1250px) 100%);
  &.right {
    clip-path: polygon( calc(50% + 1250px) 0%, 0% 0%, 0% 100%, calc(50% + 1250px) 100%);
    .text-section {
      &__content {
        justify-content: flex-start;
        &__body-container {
          margin-left: 60%;
          margin-right: 20px;
        }
      }
    }
  }
  &__content {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    &__body-container {
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      margin-right: 60%;
      max-width: 720px;
      flex: 1;
      justify-content: center;
      align-items: center;
      &__header {
        position: absolute;
        top: 20px;
        left: 0;
        font-size: 100px;
        font-weight: 700;
        padding: 0px;
        user-select: none;
        white-space: nowrap;
        color: #e6e6e6; // opacity: 0.35;
      }
      &__text-container {
        z-index: 2;
        padding: 10px;
        min-height: 200px; // min-width: 460px; // border: solid 6px #7b872a;
        width: 100%; // background-color: #ffffff29;
        background-clip: padding-box;
        &__text {
          font-size: 22px; // text-indent: 20px;
          font-weight: normal; // color: #e6e6e6;
          list-style-type: square;
          ;
        }
      }
      &__logo-container {
        z-index: 2;
        padding: 20px 40px;
        min-height: 200px;
        width: 100%;
        margin: 175px 0px 50px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: flex-start;
        &__logo {
          display: block;
          margin-bottom: 20px;
          flex: 1;
          -webkit-flex: 0 1 145px;
          flex-grow: 0;
          img {
            display: block;
            width: 100%;
            min-width: 145px;
          }
        }
      }
    }
  }
}

@media screen and (min-height: 1200px) {
  .text-section {
    margin-top: 250px;
    margin-bottom: 100px;
  }
  .return-arrow-section {
    margin-bottom: 100px;
  }
}

@media screen and (max-width: 2000px) {
  .text-section {
    &.section-six {
      .text-section__content__body-container__text-container__text {
        font-size: 24px;
      }
    }
  }
}

@media screen and (max-width: 1720px) {}

@media screen and (max-width: 1610px) {}

@media screen and (max-width: 1510px) {
  .text-section {
    &__content__body-container {
      &__header {
        font-size: 95px;
      }
      &__text-container__text {
        font-size: 20px;
      }
    }
    background-position-x: calc(50% + 50px),
    calc(50% - 30px),
    50%;
    &.right {
      background-position-x: calc(50% - 50px), calc(50% + 30px), 50%;
      .text-section {
        &__content {
          &__body-container {
            margin-left: calc(60% - 40px);
          }
        }
      }
    }
    &__content {
      &__body-container {
        margin-right: calc(60% - 40px);
        &__logo-container__logo {
          img {
            min-width: 135px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1350px) {
  .text-section {
    &.section-five,
    &.section-two {
      .text-section__content__body-container__text-container__text {
        font-size: 20px;
      }
    }
    &__content__body-container {
      &__text-container__text {
        font-size: 18px;
      }
    }
  }
}

@media screen and (max-width: 1210px) {
  .text-section {
    &.section-two {
      .text-section__content__body-container__text-container__text {
        font-size: 18px;
      }
    }
    &__content__body-container {
      &__header {
        font-size: 90px;
      }
      &__text-container__text {
        font-size: 18px;
      }
    }
  }
}

@media screen and (max-width: 1100px) {
  .text-section {
    height: 675px;
    background-position-x: calc(50% + 100px), calc(50% - 50px), 50%;
    &.right {
      background-position-x: calc(50% - 100px), calc(50% + 50px), 50%;
      .text-section {
        &__content {
          &__body-container {
            margin-left: calc(60% - 50px);
          }
        }
      }
    }
    &__content__body-container {
      &__header {
        font-size: 80px;
      }
      margin-right: calc(60% - 50px);
      &__logo-container__logo {
        img {
          min-width: 120px;
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .text-section {
    &.section-six {
      .text-section__content__body-container__text-container__text {
        font-size: 20px;
      }
    }
    &__content__body-container__text-container__text {
      font-size: 16px;
    }
    background-position-x: calc(50% + 120px),
    calc(50% - 75px),
    50%;
    &.right {
      background-position-x: calc(50% - 120px), calc(50% + 75px), 50%;
      .text-section {
        &__content {
          &__body-container {
            margin-left: calc(60% - 70px);
          }
        }
      }
    }
    &__content {
      &__body-container {
        margin-right: calc(60% - 70px);
        &__logo-container__logo {
          img {
            min-width: 110px;
          }
        }
      }
    }
  }
}